<template>
    <div>
        <div class="container view-container">
            <el-form :inline="true" class="data-form" :label-position="labelPosition" :label-width="labelWidth">
                <el-form-item :label="$t('TEXT0093')"
                >
                    <el-input readonly v-model="input.model.id"/>
                </el-form-item>
                <el-form-item :label="$t('TEXT0220')"
                              :error="input.error.fullName"
                >
                    <el-input :placeholder="$t('TEXT0060')"
                              v-model="input.model.fullName"/>
                </el-form-item>

                <el-form-item :label="$t('TEXT0019')"
                >
                    <el-input readonly v-model="input.model.email"/>
                </el-form-item>
                <el-form-item :label="$t('TEXT0001')"
                >
                    <el-select disabled v-model="input.model.countryId">
                        <el-option v-for="(item, index) in countries" :label="item.name" :value="item.id" :key="index"/>
                    </el-select>
                </el-form-item>
                <el-form-item v-if="config.showGender" :label="$t('TEXT0059')">
                    <el-select v-model="input.model.gender">
                        <el-option v-for="(textCode, code,  index) in GENDER" :label="$t(textCode)" :value="code"
                                   :key="index"/>
                    </el-select>
                </el-form-item>
                <el-form-item v-if="config.showBirthday" :label="$t('TEXT0197')" :error="input.error.birthdate" >
                    <el-row :gutter="16">
                        <el-col :span="8">
                            <el-select v-model="input.model.year "
                                       style="width: 100%"
                                       collapse-tags
                                       :placeholder="$t('TEXT0221')">
                                <el-option v-for="item in years" :key="item.index" :label="item.value"
                                           :value="item.index">
                                </el-option>
                            </el-select>
                        </el-col>
                        <el-col :span="8">
                            <el-select v-model="input.model.month"
                                       collapse-tags
                                       style="width: 100%"
                                       :placeholder="$t('TEXT0222')">
                                <el-option v-for="item in months" :key="item.index" :label="item.value"
                                           :value="item.index">
                                </el-option>
                            </el-select>
                        </el-col>
                        <el-col :span="8">
                            <el-select v-model="input.model.day"
                                       collapse-tags
                                       style="width: 100%"
                                       :placeholder="$t('TEXT0223')">
                                <el-option v-for="item in days" :key="item.index" :label="item.value"
                                           :value="item.index">
                                </el-option>
                            </el-select>
                        </el-col>
                    </el-row>
                </el-form-item>

                <el-form-item :label="$t('TEXT0062')">
                    <el-select v-model="input.model.meaningId" disabled>
                        <el-option v-for="(item,  index) in meanings" :label="item.meaning" :value="item.id"
                                   :key="index"/>
                    </el-select>
                </el-form-item>

                <el-form-item :label="$t('TEXT0098')">
                    <el-input readonly v-model="input.model.kanji"/>
                </el-form-item>
                <el-form-item :label="$t('TEXT0099')">
                    <el-rate disabled style="margin-top: 5px"
                             v-model="input.model.rating"/>
                </el-form-item>
                <el-form-item :label="$t('TEXT0206')">
                    <label class="el-form-item__label" style="color: #333333">{{ input.model.surveys }}</label>
                </el-form-item>
                <div style="display: flex; justify-content: flex-end ">
                    <el-button :loading="loading.update" type="primary" class="btn-update" @click="updateProfile">
                        {{ $t('TEXT0049') }}
                    </el-button>
                </div>
            </el-form>
        </div>
        <div class="container view-container">
            <el-table :data="shopViews" border>
                <el-table-column :label="$t('TEXT0114')" align="center">
                    <span slot-scope="scope">{{ scope.row.createdAt | datetimeFilter }}</span>
                </el-table-column>
                <el-table-column :label="$t('TEXT0115')" prop="name" align="center"/>
            </el-table>
            <div style="display: flex;">
                <el-pagination
                    background
                    :current-page.sync="paging.page"
                    :total="paging.total"
                    layout="prev, pager, next"
                    style="display:inline-block; margin-left:auto; margin-top: 10px; margin-bottom: 15px"
                    @current-change="getActionHistory">
                </el-pagination>
            </div>
        </div>
        <SuccessDialog :title="$t('TEXT0125')"
                       :message="$t('TEXT0126')"
                       okText="OK"
                       v-model="popup.update.show"/>
    </div>

</template>

<script>
import GuestApi from "@/api/GuestApi";
import ActionHistoryApi from "@/api/ActionHistoryApi";
import CountryApi from "@/api/CountryApi";
import constant from "@/config/constant";
import MeaningApi from "@/api/MeaningApi";
import EventBus from "@/config/EventBus";
import SuccessDialog from "@/components/SuccessDialog";
import SurveyApi from "@/api/SurveyApi";
import moment from "moment";
import ConfigApi from "@/api/ConfigApi"

export default {
    name: "Profile",
    components: { SuccessDialog },
    computed: {
        labelPosition: function() {
            return window.innerWidth > 992 ? 'left' : "top"
        },
        labelWidth: function() {
            return window.innerWidth > 992 ? '200px' : "180px"
        },
        GENDER: function() {
            return constant.GENDER
        },
        datePickerOption: function() {
            return {
                disabledDate(time) {
                    return time.getTime() > Date.now();
                },
            }
        },
        years() {
            const year = new Date().getFullYear()
            let arr = []
            for (let i = 0; i <= year - 1900; i++) {
                arr.push({
                  index: year - i,
                  value: year - i
                })
            }
            return arr;
        },
        days() {
            let arr = []
            for (let i = 1; i <= 31; i++) {
                arr.push({
                    index: i,
                    value: i
                })
            }
            return arr;
        },
        months() {
          let arr = []
          for (let i = 1; i <= 12; i++) {
            arr.push({
              index: i,
              value: i
            })
          }
          return arr;
        },
    },
    mounted() {
        this.getProfile()
        this.getActionHistory()
        this.getCountry()
        this.getMeanings()
        this.getSurvey()
        EventBus.$on('localeChange', () => {
            if (this.$route.path == "/profile") {
                this.getCountry()
                this.getMeanings()
                this.getActionHistory()
                this.getSurvey()
                this.setNull(this.input.error)
            }
        })
    },
    created(){
        this.getSettingConfig()
    },
    data() {
        return {
            input: {
                model: {
                    id: null,
                    fullName: null,
                    birthdate: null,
                    month: null,
                    year: null,
                    day: null,
                    email: null,
                    countryId: null,
                    gender: null,
                    meaningId: null,
                    kanji: null,
                    rating: null,
                    surveys: null
                },
                error: {
                    id: null,
                    fullName: null,
                    birthdate: null,
                    email: null,
                    countryId: null,
                    gender: null,
                    meaningId: null,
                    kanji: null,
                    rating: null,
                    surveys: null
                }
            },
            popup: {
                update: {
                    show: false
                }
            },
            loading: {
                update: false
            },
            countries: [],
            meanings: [],
            shopViews: [],
            paging: {
                page: 1,
                limit: 10,
                total: 0
            },
            config:{}
        }
    },
    methods: {
        getMeanings: function() {
            MeaningApi.all().then(resp => {
                this.meanings = resp.data
            })
        },
        getCountry: function() {
            CountryApi.all().then(resp => {
                this.countries = resp.data;
            })
        },
        getActionHistory: function() {
            ActionHistoryApi.getShopViewHistory({
                page: this.paging.page,
                limit: this.paging.limit
            }).then(resp => {
                this.shopViews = resp.data
                this.paging.total = resp.total
            })
        },
        getSurvey: function() {
            this.input.model.surveys = null
            SurveyApi.getSurveys({ id: this.input.model.id })
                .then(resp => {
                    this.input.model.surveys = resp.data.map(item => item.question).join("; ")
                })
        },
        getProfile: function() {
            GuestApi.getProfile().then(resp => {
                for (const key of Object.keys(this.input.model)) {
                    this.input.model[key] = resp.data[key]
                }
                if (resp.data.birthdate != null){
                  const inYear = moment(resp.data.birthdate,"YYYY-MM-DD").format("YYYY")
                  this.input.model.year = inYear
                  const inMonth = moment(resp.data.birthdate,"YYYY-MM-DD").format("M")
                  this.input.model.month = inMonth
                  const inDay = moment(resp.data.birthdate,"YYYY-MM-DD").format("D")
                  this.input.model.day = inDay
                }
              }).catch(resp => {
                console.log(resp)
            })
        },
        updateProfile: function() {
            if(this.input.model.day == null && this.input.model.month == null && this.input.model.year == null){
              this.input.model.birthday = null
            }
            else{
              const day = this.input.model.day >= 10 ? this.input.model.day : `0${this.input.model.day}`
              const month = this.input.model.month >= 10 ? this.input.model.month : `0${this.input.model.month}`
              this.input.model.birthdate = `${this.input.model.year}-${month}-${day}`
            }
            this.loading.update = true
            this.setNull(this.input.error)
            GuestApi.updateProfile(this.input.model).then(resp => {
                // this.$router.push("/")
                console.log(resp)
                this.popup.update.show = true
            }).catch(resp => {
                if (resp.status === 412) {
                    for (let i = 0; i < resp.data.length; i++) {
                        this.setToValue(this.input.error, resp.data[i].key, resp.data[i].value)
                    }
                }
            }).finally(() => {
                this.loading.update = false
            })
        },
        getSettingConfig(){
            ConfigApi.getConfig()
                .then((res)=>{
                    this.config = res.data
                })
        }
    }
}
</script>

<style scoped lang="scss">

.view-container {
    background-color: white;
    margin-top: 20px;
    margin-bottom: 20px;
    padding: 40px 20px 20px 20px;
}

::v-deep .el-form-item {
    width: 100%;
}

::v-deep .el-select {
    width: 60%;
}

::v-deep .el-form-item__content {
    width: 60%;
}

::v-deep .el-select {
    width: 60%;
}

::v-deep .el-date-editor {
    width: 60%;
}

::v-deep .birthday .el-input__prefix {
    display: none;
}

::v-deep .birthday .el-input__inner {
    padding-left: 15px !important;
}

@media screen and (min-width: 992px) {
    .data-form {
        max-width: 800px;
        margin: auto;
    }
}

@media screen and (max-width: 991px) {
    .view-container {
        padding: 20px;
    }
    ::v-deep .el-form-item__content {
        width: 100%;
    }

    ::v-deep .el-select {
        width: 100%;
    }
}

</style>
